import React, { useState, useEffect } from 'react';
import WhatsAppButton from './components/WhatsAppButton';
import WhatsAppModal from './components/WhatsAppModal';
import decompressWsp  from './utils/decompressWsp';
import { saveToFirestore } from '../../utils/firebaseUtils';

const WhatsAppManager = ({ storeName, isAdminRoute, compressedWsp }) => {
  console.log("compressedWsp",compressedWsp)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [whatsApp, setWhatsApp] = useState({});
  console.log('whatsApp', whatsApp);

  useEffect(() => {
    if (compressedWsp) {
      try {
        const decompressedData = decompressWsp(compressedWsp);
        setWhatsApp(decompressedData);
      } catch (error) {
          console.error('Error al descomprimir datos de WhatsApp:', error);
      }
    }
  }, [compressedWsp]);

  const handleWhatsAppClick = () => {
    if (isAdminRoute) {
      setIsModalOpen(true);
    } else {
      const { phone, message } = whatsApp;
  
      if (!phone || phone.trim() === '') {
        console.eror('Por favor, configure un número de teléfono válido.');
        return;
      }
  
      const encodedMessage = message ? encodeURIComponent(message) : '';
      const whatsappUrl = `https://wa.me/${phone}${encodedMessage ? `?text=${encodedMessage}` : ''}`;
      window.open(whatsappUrl, '_blank');
    }
  };
  
  const handleSave = async (compressedWsp) => {
    try {
      await saveToFirestore(storeName, 'config', {whatsApp: compressedWsp
      });
      const decompressedData = decompressWsp(compressedWsp);
      setWhatsApp(decompressedData);
  
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error al guardar el servicio:', error);
    }
  };
  
  return (
    <div>
      <WhatsAppButton 
        onClick={handleWhatsAppClick} 
        position={{ bottom: '20px', right: '20px' }} 
      />
      {isAdminRoute && (
        <WhatsAppModal 
          open={isModalOpen} 
          onClose={() => setIsModalOpen(false)} 
          onSave={handleSave}
          initialWsp={whatsApp}
        />
      )}
    </div>
  );
};

export default WhatsAppManager;