import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import SkeletonLoader from './SkeletonLoader';
import CardBeneficio from './ui/CardBeneficio';

const BeneficiosPreview = ({ beneficiosArray, width = 300, height = 200, user }) => {
  
  /**
   * 1. Verificamos si `beneficiosArray` es un array o un objeto.
   *    - Si es array, lo asignamos directamente.
   *    - Si es objeto y tiene `beneficios`, tomamos `beneficiosArray.beneficios`.
   *    - Si es `null` o `undefined`, quedará `[]` (un array vacío).
   */

  const arrayBeneficios = beneficiosArray?.beneficios || [];

  
  const diseno = beneficiosArray?.disenoSeleccionado || "uiDefault";
  
  //console.log("beneficiosArray", beneficiosArray);
  // 2. Si todavía no tenemos nada en `beneficiosArray`, retornamos los skeletons:
  
  if (!beneficiosArray) {
    return (
      <Grid container spacing={2}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <SkeletonLoader width={width} height={height} />
          </Grid>
        ))}
      </Grid>
    );
  }

 
  return (
    <Box
    sx={{
      position: 'relative',
      minHeight: height,
      backgroundColor: 'white',
      width: '100%',
      ...(diseno === 'ui4' && { margin: 'auto', backgroundColor: beneficiosArray.colorFondo}), 
    }}

    className={diseno === 'ui4' ? 'top-[3rem] mt-0 md:!mt-[5%] md:top-0 md:rounded-[20px] pb-[5rem] md:!w-[90%] w-full mb-0 md:!mb-[3%] z-[1] px-[20px]  md:p-[16px] gap-[10px] md:gap-0' : ''}
    
    >
      <h1
        className="text-[32px] md:text-[40px]	 mb-0 font-semibold py-3 md:!py-5 md:text-4xl leading-normal	"
        style={{ color: beneficiosArray.colorTitulo , fontWeight: '800' }}
      >
          { beneficiosArray.tituloSeccion || "Beneficios" }

      </h1>

      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: '1300px',
          margin: '0 auto',
          width: '100%',
        }}

        className={diseno === 'ui4' ? 'gap-[10px] md:gap-[0px]' : ''}
      >
        {arrayBeneficios.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'darkgrey',
              fontSize: '14px',
              textAlign: 'center',
              width: '100%',
              height: '100%',
              paddingTop: '5%',
              paddingBottom: '5%',
              
              
            }}
          >
            No hay beneficios para mostrar
          </Box>
        ) : (

          arrayBeneficios.map((beneficio) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={beneficio.id || beneficio.titulo}

              className={diseno === 'ui4' ? 'p-0 md:p-[2%]' : ''}

            >

              <CardBeneficio
                designVariant={diseno}
                id={beneficio.id}
                titulo={beneficio.titulo}
                descripcion={beneficio.descripcion}
                porcentaje={beneficio.porcentaje}
                color={beneficio.color}
                colorStart={beneficio.colorStart || ''}
                colorEnd={beneficio.colorEnd || ''}
                angle={beneficio.angle || '180'} 
                colorTexto={beneficiosArray.colorTexto}

              />


            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};

export default BeneficiosPreview;
