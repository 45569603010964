import React from 'react';
import { Tooltip, Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton = ({ onClick, position = { bottom: '70px', right: '20px' } }) => (
  <Tooltip arrow>
    <Fab
      color="primary"
      aria-label="whatsapp"
      onClick={onClick}
      sx={{
        position: 'absolute',
        backgroundColor: '#25D366',
        color: 'white',
        ...position,
        zIndex: 1000,
      }}
    >
      <WhatsAppIcon sx={{ fontSize: '30px' }} />
    </Fab>
  </Tooltip>
);

export default WhatsAppButton;