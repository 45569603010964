import React, { useEffect, useState } from 'react';
import { CircularProgress, Alert } from '@mui/material';
import Blog from '../components/Blog/BlogManager';
import { doc, getDoc } from 'firebase/firestore'; 
import { db } from '../firebaseConfig'; 
import { useParams, useLocation } from 'react-router-dom';
import useStoreName from '../hooks/useStoreName'; 
import useStore from '../hooks/useStore'; 
import NavbarManager from '../components/Navbar/NavbarManager';
import BannerManager from '../components/Banner_new/BannerManager';
import ServicioManager from '../components/Servicios/ServicioManager';
import BeneficiosManager from '../components/Beneficios/BeneficiosManager';
import GaleriaManager from '../components/Galeria/GaleriaManager';
import ProductoManager from '../components/Producto/ProductoManager';
import Footer from '../components/Footer/components/Footer';
import SplashScreenManager from '../components/SplashScreen/SplashScreenManager';
import TikTokManager from '../components/TikTok/TiktokManager';
import PowerBIManager from '../components/PowerBI/PowerBIManager';
import PoliticaPrivacidadManager from '../components/PoliticaPrivacidad/PoliticaPrivacidadManager';
import WhatsAppManager from '../components/WhatsApp/WhatsAppManager';

const MarketTema1 = () => {
  
  const { storeName, isAdminRoute } = useStoreName();
  const location = useLocation();
  const { tienda } = useParams();
  const {
    loading,
    error,
    storeConfig,
    getStoreConfig,
    features
  } = useStore();

  const isTikTokEnabled = features?.inteTictokProducto === true;
  useEffect(() => {
    if (storeName) {
      getStoreConfig(storeName);
    }
  }, [storeName]);
  
  
  if (loading) {
    return <SplashScreenManager />;
  }
  
  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  
  if (!storeConfig || !features) {
    return null; 
  }
  
  //console.log('storeConfig', storeConfig)
  const featureComponents = [
    {
      key: 'header',
      component: (
        <NavbarManager
          storeName={storeName}
          compressedLogo={storeConfig?.logoData}
          compressedNavbar={storeConfig?.navbarData}
        />
      )
    },
    {
      key: 'banner',
      component: <BannerManager storeName={storeName} compressedBanner={storeConfig?.banner} />
    },
    {
      key: 'servicios',
      component: <ServicioManager storeName={storeName} compressedServicios={storeConfig?.servicios}/>
    },
    {
      key: 'beneficios',
      component: <BeneficiosManager storeName={storeName} compressedBeneficios={storeConfig?.beneficios}  />
    },
    {
      key: 'galeria',
      component: <GaleriaManager storeName={storeName} isAdminRoute={isAdminRoute} compressedGaleria={storeConfig?.galeria}/>
    },
    {
      key: 'productos',
      component: <ProductoManager storeName={storeName} isAdminRoute={isAdminRoute} isTikTokEnabled={isTikTokEnabled}/>
    },
    {
      key: 'blog',
      component: <Blog storeName={storeName} isAdminRoute={isAdminRoute} isTikTokEnabled={isTikTokEnabled}/>
    },
    {
      key: 'intePowerBI', 
      component: <PowerBIManager storeName={storeName} isAdminRoute={isAdminRoute}  compressedReportes={storeConfig?.reportes}/>
    },
    {
      key: 'politicasPrivacidad',
      component: <PoliticaPrivacidadManager storeName={storeName} isAdminRoute={isAdminRoute} />
    },
    {
      key: 'footer',
      component: <Footer storeName={storeName} compressedFooter={storeConfig?.footer} isAdminRoute={isAdminRoute} />
    },
    {
      key: 'inteTictokProducto', 
      component: <TikTokManager storeName={storeName} isAdminRoute={isAdminRoute} />
    },
    ,
    {
      key: 'pluginWhatsAppBtn', 
      component: <WhatsAppManager storeName={storeName} isAdminRoute={isAdminRoute}  compressedWsp={storeConfig?.whatsApp}/>
    }
  ];


  return (
    <div>
      <div className="splash-screen--visible">
        {featureComponents.map(({ key, component }, index) =>
          features[key] ? (
            <React.Fragment key={index}>
              {component}
            </React.Fragment>
          ) : null
        )}
      </div>
    </div>
  );
};

export default MarketTema1;
