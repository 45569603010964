import React from 'react';
import FooterWidget from '../components/FooterWidget';
import {
  Box,
  Fab,
  Tooltip,
  Modal,
  TextField,
  Button,
  IconButton,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';






const Diseño1 = ({ footerContent, storeName, isAdminRoute,user,handleOpenModal}) => (
  
  <footer
    className="footer-area"
    style={{
      backgroundColor: footerContent.footerBackgroundColor,
      color: footerContent.footerTextColor,
    }}
  >
    <div className="container">
      <div className="row gap-[20px] md:gap-0">
        
      <div className='col-lg-3 col-md-6 col-sm-6'>
        <div className='single-footer-widget mb-0 md:mb-[30px]'>

            <h3 className='text-left !normal-case font-bold before:!bg-transparent'>Redes Sociales</h3>

        </div>

        <div className='flex gap-2 items-center'>
        {footerContent?.socialLinks?.map((link, index) => (
          <a href={link.url} target="_blank" rel="noreferrer">
            <div className='text-white p-2 rounded-[50%] min-w-[46px] min-h-[46px] flex  justify-center  items-center' style={{
                backgroundColor: link.bgColor }}>
                <img src={link.icon} className='max-w-[30px] max-h-[30px]'/>
            </div>  
            </a>
        ))}

        </div>
        
      </div>





        <FooterWidget
          title="Enlaces Rápidos"
          links={footerContent.quickLinks}
          footerTextColor={footerContent.footerTextColor}
          footerTextoTodo={footerContent.footerTextoTodo}
        />

        <FooterWidget
          title="Contáctenos"
          links={footerContent.information}
          footerTextColor={footerContent.footerTextColor}
          footerTextoTodo={footerContent.footerTextoTodo}


        />

  {/*
        <FooterWidget
          title="Noticias nuevas"
          links={footerContent.centroAyuda}
          footerTextColor={footerContent.footerTextColor}
          footerTextoTodo={footerContent.footerTextoTodo}
        />
  */}

       

      </div>
    </div>
    <div
      className="copyright-area"
      style={{
        backgroundColor: footerContent.footerBackgroundColor,
        color: footerContent.footerTextoTodo,
      }}
    >
      <div className="container">
        <div className="align-items-center">
          <div className="text-[15px]">
            <p style={{ color: footerContent.footerTextoTodo }}>{footerContent.copyright}</p>
          </div>
         
        </div>
      </div>
    </div>

    
      
  </footer>
);

export default Diseño1;
