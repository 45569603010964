import React, { useState, useEffect } from 'react';
import { Tooltip, Fab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const GaleriaEditButton = ({ onClick }) => {
  
  return (
    <>
        <Tooltip title="Editar Galeria" arrow>
            <Fab color="primary"
                aria-label="edit"
                onClick={onClick}
                style={{
                    position: 'absolute',
                    bottom: '1rem',
                    right: '1rem',
                    backgroundColor: 'white',
                }}
            >
            <EditIcon style={{ color: '#0000008a' }}/>
            </Fab>
        </Tooltip>
    </>
  );
};

export default GaleriaEditButton;